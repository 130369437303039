<template>
  <!-- 登录 -->
  <div class="page">
    <div style="width: 500px">
      <div class="title">系统登录</div>
      <!-- 登录表单 -->
      <div class="form">
        <el-input
          placeholder="用户名"
          v-model="form.user"
          clearable
          prefix-icon="el-icon-user"
          style="margin: 25px 0; background-color: #2d3a4b"
        >
        </el-input>
        <el-input
          placeholder="密码"
          v-model="form.pwd"
          prefix-icon="el-icon-lock"
          show-password
          class="input"
        >
        </el-input>
      </div>
      <el-button
        type="primary"
        style="width: 100%; margin-top: 50px"
        :loading="isStore"
        @click="onLogin"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        user: "",
        pwd: "",
      },
      isStore: false,
    };
  },
  methods: {
    onLogin() {
      this.isStore = true;
      this.axios
        .get("/admin/login/adminLogin", {
          account: this.form.user,
          pwd: this.form.pwd,
          supers: 1,
        })
        .then((res) => {
          this.isStore = false;
          // 登录成功
          if (res.data.code == 200) {
            // 登录成功
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem(
              "admin",
              JSON.stringify(res.data.data.admin)
            );
            sessionStorage.setItem(
              "sidebar",
              JSON.stringify(res.data.data.action)
            );
            this.$message({
              message: "登录成功",
              type: "success",
            });
            // 跳转首页
            this.$router.push("/");
          } else if (res.data.code == 400) {
            this.$message({
              message: res.data.mesg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.isStore = false;
          // this.$message({
          //   message: "登录失败",
          //   type: "error",
          // });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #2d3a4b;
  justify-content: center;
}
.title {
  text-align: center;
  margin-top: 150px;
  color: #eeeeee;
  font-size: 26px;
  font-weight: bold;
}
</style>
